@import "../../../../../presentation/styles/colors.css";

.login-content {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
  margin-bottom: 0;
}

.login-content > button > span {
  text-transform: none;
  font-weight: 500;
}

.login-image-bg {
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30rem;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  background-color: var(--white);
  margin: 15px;
  padding: 40px;
  border-radius: 8px;
  box-shadow: hsla(210deg 50% 50%/ 30%) 0 1px 2px 0, hsla(210deg 50% 50%/ 15%) 0 1px 3px 1px;
}

.login-container .divisor {
  border: 5px solid var(--primary-color);
  border-radius: 5px;
  width: 100%;
}

.login-container .main-area {
  width: 100%;
}

.login-container .main-area .login-destaque-area {
  width: 75%;
  margin: auto;
}

.login-titulo-principal {
  margin-top: 30px;
  margin-bottom: -30px;
  text-align: center;
}

.login-form-container {
  flex-basis: 95%;
}

.login-form-container span[class^="sds-caption"] {
  width: auto;
  position: relative;
  margin: 10px auto;
}

.login-form-container button[class^="sds-input__icon"] {
  width: auto;
  margin: 0;
  right: 0.375rem;
}

.hr-sect {
  display: flex;
  flex-basis: 100;
  align-items: center;
  color: hsla(0deg 0% 0%/ 35%);
  margin: 8px 0;
  text-transform: lowercase;
}

.hr-sect::before,
.hr-sect::after {
  content: "";
  flex-grow: 1;
  background: hsla(0deg 0% 0%/ 35%);
  height: 1px;
  font-size: 0;
  line-height: 0;
  margin: 0 8px;
}

.login-space {
  margin-top: 30px;
}

.link-style {
  font-size: 0.875rem;
}

.link-style:hover {
  text-decoration: underline;
  color: #135c96;
  cursor: pointer;
}

.tipos-login {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
  margin-top: 1rem;
}

.tipos-login.justify-center {
  display: flex;
  justify-content: center;
}

.small-icon-link {
  margin-right: 2px;
  font-size: 20px;
}
