/* stylelint-disable selector-class-pattern */
.processo__header-content--padding {
  padding: 20px 0;
}

.processo__tab-content--padding {
  padding: 20px;
}

.processo__tab-content--item {
  display: flex;
}

.processo__tab-content--item-title {
  font-weight: bold;
  text-align: right;
  min-width: 200px;
  max-width: 200px;
}

.processo__tab-content--item div:nth-child(2) {
  padding: 0 5px;
}

.processo__tab-content--subtitle {
  padding: 15px 0;
  font-size: 28px;
}

.processo__tab-content--subtitle div span:nth-child(1) {
  padding-bottom: 5px;
}

.processo__tab-iframe {
  min-height: 700px;
}

@media only screen and (max-width: 36em) {
  .processo__tab-content--item {
    display: inline;
  }

  .processo__tab-content--item-title {
    text-align: initial;
    min-width: initial;
    max-width: initial;
  }

  .processo__tab-content--item div:nth-child(2) {
    padding: 5px 0;
  }
}
