/* stylelint-disable selector-class-pattern */

@import "../../styles/colors.css";

.language-menu__container {
  display: flex;
  cursor: pointer;
  right: 100px;
  font-size: 16px;
  z-index: 1;
}

.language-menu__button {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  color: var(--white);
  background: none;
  border: none;
  cursor: pointer;
}

.language-menu__icon {
  font-size: 18px;
}


/* Submenu */
.language-menu__submenu {
  /* width: 100%; */
  background-color: var(--white);
  display: none;
  position: absolute;
  padding: 10px;
  list-style-type: none;
  margin-top: 0.625rem;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
}

.language-menu__languages {
  color: var(--black);
  border: none;
  background: none;
  cursor: pointer;
}

.language-menu__dropdown--active {
  display: block;
}

@media only screen and (max-width: 1400px) {
  .language-menu__title {
    display: none;
  }
}