/* stylelint-disable no-descending-specificity */

.info-modal-overlay {
  background: rgb(0 0 0 / 75%);
  position: fixed;
  inset: 0;
  z-index: 999;
}

.info-modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #fff;
  padding: 14px 28px;
  border-radius: 5px;
  width: auto;
  height: auto;
  z-index: 1000;
}

.info-modal-title {
  text-align: center;
  color: #00838F;
}


.info-modal-icon-cotainer {
  height: 50px;
  animation: fade-in 1s;
  vertical-align: middle;
  border-style: none;
}

.info-modal-description {
  text-align: center;
  line-height: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.info-modal-button-close {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
  border: none;
  cursor: pointer;
  background: transparent;
}

.info-modal-icon-close {
  color: #00838F;
  font-size: 1.625em;
}

@media only screen and (max-width: 2560px) {
  .info-modal-description {
    font-size: 1.25em;
  }
}

@media only screen and (max-width: 1440px) {
  .info-modal-description {
    font-size: 1em;
  }
}

@media only screen and (max-width: 768px) {
  .info-modal-content {
    width: 60%;
  }
}

@media only screen and (max-width: 425px) {
  .info-modal-content {
    width: 70%;
  }
}

.group-div {
  display: inline-block;
  width: '300px';
  justify-content: 'center';
  align-items: 'center';
}
