/* stylelint-disable selector-class-pattern */

.app-tool-bar-skip-to-content {
  background-color: var(--white);
  color: var(--client-main-color);
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  position: absolute;
  left: 0.5rem;
  transform: translateY(-250%);
  transition: transform 325ms ease-in;
  box-shadow: rgb(149 157 165 / 20%) 0 8px 24px;
  text-decoration: none;
  z-index: 1;
}

/* .app-tool-bar-skip-to-content:focus {
  transform: translateY(0);
} */

.app-tool-bar-utils-container {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  align-content: center;
  gap: 20px;
  position: absolute;
  right: 1%;
  height: 4rem;
}

.app-tool-bar-utils-content {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-right: 40px;
}

.app-tool-bar-divider-container {
  height: 0;
  padding: 0;
  margin: 0;
  position: relative;
  display: contents;
}

@media only screen and (max-width: 1280px) {
  .app-tool-bar-utils-container {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .app-menu__logo {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}
