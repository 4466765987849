/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-class-pattern */

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.portal-col__wrapper {
  height: 100%;
  min-height: max-content;
  display: flex;
  flex-direction: column;
}

#root > .portal-col__wrapper {
  min-height: 100vh;
}

.portal-flex-1 {
  flex: 1;
  -webkit-box-flex: 1;
}

.portal-assinatura-refresh-btn {
  padding: 0.125rem 0.5rem 0.125rem 0;
  position: relative;
  top: 24px;
  height: 40px;
  box-sizing: border-box;
}

.portal-step-row {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.portal-step-circle {
  position: relative;
  margin-right: 0.5rem;
  border-radius: 50%;
  border: 3px solid #eee;
  width: 2.5rem;
  height: 2.5rem;
  box-sizing: border-box;
}

.portal-step-circle .sds-load__content {
  height: 3rem;
  width: 3rem;
  margin: -0.4375rem;
  opacity: 1;
  transition: opacity 250ms;
}

.portal-step-circle h6 {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.125rem;
  height: 2.125rem;
  padding: 0.25rem;
  text-align: center;
  box-sizing: border-box;
  border-radius: 50%;
}

.portal-success {
  position: absolute;
  top: -0.1875rem;
  left: -0.1875rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #5a9930;
  color: var(--white);
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 250ms;
}

.portal-success.portal-success--show {
  opacity: 1;
}

.portal-step-indicator {
  width: 0.25rem;
  margin: 0 1.125rem;
  background-color: #eee;
  height: 1rem;
}

.portal-step-indicator.portal-step-indicator--success {
  background-color: rgb(65 129 23);
}

.portal-step-indicator:last-child {
  background-color: transparent;
}

.portal-step-row.portal-step-row--disabled .sds-load__content {
  opacity: 0;
}

.portal-step-row.portal-step-row--disabled h6,
.portal-step-row.portal-step-row--disabled p {
  color: rgb(0 0 0 / 40%);
}

.portal-empty-state-illustration {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 156px;
  height: 156px;
  margin: 0 auto 1.5rem;
  border-radius: 50%;
  background-color: #ddd;
}

.portal-empty-state-illustration img {
  width: 90px;
  height: auto;
}

@media only screen and (min-width: 36em) {
  .portal-empty-state-illustration {
    width: 240px;
    height: 240px;
  }

  .portal-empty-state-illustration img {
    width: 140px;
  }
}

.portal-text-icon {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.portal-text-icon i {
  margin-left: 0.5rem;
  color: rgb(0 0 0 / 60%);
  cursor: default;
}

.portal-success-load svg circle {
  stroke: rgb(65 129 23);
}

.portal-json-editor input {
  width: 100%;
}

.portal-json-editor div > div > div:nth-child(2) {
  width: 100%;
}

.portal-full-heigth {
  height: 100%;
}

.rotate-180 {
  transform: rotate(180deg);
}

@media print {
  .no-print {
    display: none;
    visibility: hidden;
  }
}

.field-hidden {
  display: none;
}

/* menu */

.menu-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.menu-icon .menu-icon__img.menu-icon__img--open {
  margin-right: 1em;
}

.sds-sidemenu .sds-sidemenu__item .menu-icon__label,
.menu-icon__label {
  font-size: 12px;
  opacity: 1;
  margin-top: 5px;
}

.TextField {
  width: 100%;
  height: 2.5rem;
  padding: 0.375rem 0.75rem;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  color: rgb(0 0 0 /87%);
  border: 1px solid rgb(0 0 0 / 24%);
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  transition: border-color 150ms;
}

a.columnCustom {
  color: var(--primary-color);
}

a:visited.columnCustom {
  color: var(--primary-color);
}

.main-content-container {
  height: 100%;
}