.icon-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  fill: var(--white);
  transform: rotate(180deg);
  transition: all 150ms;
}

.icon-menu-active {
  transform: rotate(0deg);
}