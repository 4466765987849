/* stylelint-disable no-descending-specificity */

.info-modal-overlay {
  background: rgb(0 0 0 / 75%);
  position: fixed;
  inset: 0;
  z-index: 999;
}

.info-modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #fff;
  padding: 14px 28px;
  border-radius: 5px;
  width: auto;
  height: auto;
  z-index: 1000;
}

.info-modal-title {
  text-align: center;
  color: #00838F;
}


.info-modal-icon-cotainer {
  height: 50px;
  animation: fade-in 1s;
  vertical-align: middle;
  border-style: none;
}

.info-modal-description {
  text-align: center;
  line-height: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.info-modal-button-close {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
  border: none;
  cursor: pointer;
  background: transparent;
}

.info-modal-icon-close {
  color: #00838F;
  font-size: 1.625em;
}

@media only screen and (max-width: 2560px) {
  .info-modal-description {
    font-size: 1.25em;
  }
}

@media only screen and (max-width: 1440px) {
  .info-modal-description {
    font-size: 1em;
  }
}

@media only screen and (max-width: 768px) {
  .info-modal-content {
    width: 60%;
  }
}

@media only screen and (max-width: 425px) {
  .info-modal-content {
    width: 70%;
  }
}

.group-div {
  display: inline-block;
  width: '300px';
  justify-content: 'center';
  align-items: 'center';
}

/* stylelint-disable color-function-notation */
/* stylelint-disable selector-not-notation */
.br-button {
  --button-background: transparent;
  --button-border: transparent;
  --button-color: var(--interactive);
  --button-radius: 100em;
  --button-font-size: var(--font-size-scale-up-01);
  --button-font-weight: var(--font-weight-semi-bold);
  --button-xsmall: 24px;
  --button-small: 32px;
  --button-medium: 40px;
  --button-large: 48px;
  --button-size: var(--button-medium);
  --button-padding: 0 var(--spacing-scale-3x);
  --button-img: 20px;
  --interactive-rgb: var(--rgb-primary-default);

  align-items: center;
  background-color: var(--button-background);
  border: 1px solid var(--button-border);
  border-radius: var(--button-radius);
  color: var(--button-color);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--button-font-size);
  font-weight: var(--button-font-weight);
  height: var(--button-size);
  justify-content: center;
  padding: var(--button-padding);
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.br-button.block {
  width: 100%;
}

@media (min-width: 575px) {
  .br-button.block-sm {
    width: 100%;
  }

  .br-button.auto-sm {
    width: auto;
  }
}

@media (min-width: 992px) {
  .br-button.block-md {
    width: 100%;
  }

  .br-button.auto-md {
    width: auto;
  }
}

@media (min-width: 1280px) {
  .br-button.block-lg {
    width: 100%;
  }

  .br-button.auto-lg {
    width: auto;
  }
}

@media (min-width: 1600px) {
  .br-button.block-xl {
    width: 100%;
  }

  .br-button.auto-xl {
    width: auto;
  }
}

.button-login-certificado-digital {
  --button-border: transparent;
  --button-radius: 100em;
  --button-font-size: var(--font-size-scale-up-01);
  --button-font-weight: var(--font-weight-semi-bold);
  --button-xsmall: 24px;
  --button-small: 32px;
  --button-medium: 40px;
  --button-large: 48px;
  --button-size: var(--button-medium);
  --button-padding: 0 var(--spacing-scale-3x);
  --button-img: 20px;

  align-items: center;
  background-color: var(--button-background);
  border: 1px solid var(--button-border);
  border-radius: var(--button-radius);
  color: var(--button-color);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--button-font-size);
  font-weight: var(--button-font-weight);
  height: var(--button-size);
  justify-content: center;
  padding: var(--button-padding);
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;

  --interactive-rgb: var(--rgb-secondary-01);
  --button-background: var(--primary-color);
  --button-color: var(--color-secondary-01);
}

.br-button.primary,
.br-button[primary],
.br-button.is-primary {
  --interactive-rgb: var(--rgb-secondary-01);
  --button-background: var(--color-primary-default);
  --button-color: var(--color-secondary-01);
}

.br-button:not(:disabled):not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}

:root {
  --hover: var(--surface-opacity-xs, 0.16);
  --color-primary-default: #1351b4;
  --color-secondary-01: #fff;
  --font-weight-semi-bold: 600;
  --font-size-scale-up-01: 1.05em;
  --rgb-secondary-01: 255, 255, 255;
}

.govbr-area .descricao {
  background-color: #c7c7c761;
  padding: 5px;
  margin-bottom: 2.75rem;
}

:root {
  --primary-color: #00838F;
  --primary-medium: #0097A7;
  --client-main-color: var(--primary-color);
  --client-main-color-rgb-70: var(--primary-medium);
  --client-main-color-rgb-40: var(--primary-light);
  --client-main-color-rgb-10: var(--primary-xl);
  --client-secondary-color: var(--secondary-color);
  --client-secondary-color-rgb-70: var(--secondary-medium);
  --client-secondary-color-rgb-40: var(--secondary-light);
  --client-secondary-color-rgb-10: var(--secondary-xl);
  --client-neutral-color: var(--neutral-color);
  --client-neutral-color-rgb-70: var(--neutral-color-medium);
  --client-neutral-color-rgb-40: var(--neutral-color-light);
  --client-neutral-color-rgb-10: var(--neutral-color-xl);
  --client-background: var(--background);
  --client-background-light: var(--background-light);
  --black: #000;
  --white: #fff;
  --yellow: #ff0;
  --red: #f00;
  --grey: #747474;
  --grey-light: #d3d3d3;
  --grey-dark: #413F42;
}

.login-content {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
  margin-bottom: 0;
}

.login-content > button > span {
  text-transform: none;
  font-weight: 500;
}

.login-image-bg {
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 30rem;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  background-color: var(--white);
  margin: 15px;
  padding: 40px;
  border-radius: 8px;
  box-shadow: hsla(210deg 50% 50%/ 30%) 0 1px 2px 0, hsla(210deg 50% 50%/ 15%) 0 1px 3px 1px;
}

.login-container .divisor {
  border: 5px solid var(--primary-color);
  border-radius: 5px;
  width: 100%;
}

.login-container .main-area {
  width: 100%;
}

.login-container .main-area .login-destaque-area {
  width: 75%;
  margin: auto;
}

.login-titulo-principal {
  margin-top: 30px;
  margin-bottom: -30px;
  text-align: center;
}

.login-form-container {
  flex-basis: 95%;
}

.login-form-container span[class^="sds-caption"] {
  width: auto;
  position: relative;
  margin: 10px auto;
}

.login-form-container button[class^="sds-input__icon"] {
  width: auto;
  margin: 0;
  right: 0.375rem;
}

.hr-sect {
  display: flex;
  flex-basis: 100;
  align-items: center;
  color: hsla(0deg 0% 0%/ 35%);
  margin: 8px 0;
  text-transform: lowercase;
}

.hr-sect::before,
.hr-sect::after {
  content: "";
  flex-grow: 1;
  background: hsla(0deg 0% 0%/ 35%);
  height: 1px;
  font-size: 0;
  line-height: 0;
  margin: 0 8px;
}

.login-space {
  margin-top: 30px;
}

.link-style {
  font-size: 0.875rem;
}

.link-style:hover {
  text-decoration: underline;
  color: #135c96;
  cursor: pointer;
}

.tipos-login {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
  margin-top: 1rem;
}

.tipos-login.justify-center {
  display: flex;
  justify-content: center;
}

.small-icon-link {
  margin-right: 2px;
  font-size: 20px;
}

/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-class-pattern */

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.portal-col__wrapper {
  height: 100%;
  min-height: -webkit-max-content;
  min-height: max-content;
  display: flex;
  flex-direction: column;
}

#root > .portal-col__wrapper {
  min-height: 100vh;
}

.portal-flex-1 {
  flex: 1 1;
  -webkit-box-flex: 1;
}

.portal-assinatura-refresh-btn {
  padding: 0.125rem 0.5rem 0.125rem 0;
  position: relative;
  top: 24px;
  height: 40px;
  box-sizing: border-box;
}

.portal-step-row {
  display: flex;
  align-items: center;
}

.portal-step-circle {
  position: relative;
  margin-right: 0.5rem;
  border-radius: 50%;
  border: 3px solid #eee;
  width: 2.5rem;
  height: 2.5rem;
  box-sizing: border-box;
}

.portal-step-circle .sds-load__content {
  height: 3rem;
  width: 3rem;
  margin: -0.4375rem;
  opacity: 1;
  transition: opacity 250ms;
}

.portal-step-circle h6 {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.125rem;
  height: 2.125rem;
  padding: 0.25rem;
  text-align: center;
  box-sizing: border-box;
  border-radius: 50%;
}

.portal-success {
  position: absolute;
  top: -0.1875rem;
  left: -0.1875rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #5a9930;
  color: var(--white);
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 250ms;
}

.portal-success.portal-success--show {
  opacity: 1;
}

.portal-step-indicator {
  width: 0.25rem;
  margin: 0 1.125rem;
  background-color: #eee;
  height: 1rem;
}

.portal-step-indicator.portal-step-indicator--success {
  background-color: rgb(65 129 23);
}

.portal-step-indicator:last-child {
  background-color: transparent;
}

.portal-step-row.portal-step-row--disabled .sds-load__content {
  opacity: 0;
}

.portal-step-row.portal-step-row--disabled h6,
.portal-step-row.portal-step-row--disabled p {
  color: rgb(0 0 0 / 40%);
}

.portal-empty-state-illustration {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 156px;
  height: 156px;
  margin: 0 auto 1.5rem;
  border-radius: 50%;
  background-color: #ddd;
}

.portal-empty-state-illustration img {
  width: 90px;
  height: auto;
}

@media only screen and (min-width: 36em) {
  .portal-empty-state-illustration {
    width: 240px;
    height: 240px;
  }

  .portal-empty-state-illustration img {
    width: 140px;
  }
}

.portal-text-icon {
  display: flex;
  align-items: center;
}

.portal-text-icon i {
  margin-left: 0.5rem;
  color: rgb(0 0 0 / 60%);
  cursor: default;
}

.portal-success-load svg circle {
  stroke: rgb(65 129 23);
}

.portal-json-editor input {
  width: 100%;
}

.portal-json-editor div > div > div:nth-child(2) {
  width: 100%;
}

.portal-full-heigth {
  height: 100%;
}

.rotate-180 {
  transform: rotate(180deg);
}

@media print {
  .no-print {
    display: none;
    visibility: hidden;
  }
}

.field-hidden {
  display: none;
}

/* menu */

.menu-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.menu-icon .menu-icon__img.menu-icon__img--open {
  margin-right: 1em;
}

.sds-sidemenu .sds-sidemenu__item .menu-icon__label,
.menu-icon__label {
  font-size: 12px;
  opacity: 1;
  margin-top: 5px;
}

.TextField {
  width: 100%;
  height: 2.5rem;
  padding: 0.375rem 0.75rem;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  color: rgb(0 0 0 /87%);
  border: 1px solid rgb(0 0 0 / 24%);
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  transition: border-color 150ms;
}

a.columnCustom {
  color: var(--primary-color);
}

a:visited.columnCustom {
  color: var(--primary-color);
}

.main-content-container {
  height: 100%;
}
/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-class-pattern */

/* Componente */
.high-contrast__content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8125em;
  color: var(--white);
  right: 220px;
  grid-gap: 5px;
  gap: 5px;
  cursor: pointer;
  border: none;
  background: none;
}

.high-contrast__icon {
  font-size: 18px;
}

/* Background */
.high-contrast,
.high-contrast nav,
.high-contrast div,
.high-contrast li,
.high-contrast ol,
.high-contrast header,
.high-contrast footer,
.high-contrast section,
.high-contrast main,
.high-contrast aside,
.high-contrast article {
  background: var(--black) !important;
  color: var(--white) !important;
}

/* Cabeçalho */
.high-contrast .sds-topbar {
  border-bottom: solid 1px var(--white) !important;
}

/* Menu de Navegação */
.high-contrast .sds-sidemenu {
  border-right: solid 1px var(--white) !important;
}

.high-contrast .sds-sidemenu i,
.high-contrast .sds-sidemenu span {
  color: var(--yellow) !important;
}

.high-contrast .sds-sidemenu__item--active a i {
  color: var(--white) !important;
}

/* Menu de idiomas */
.high-contrast .language-menu-dropdown-submenu {
  color: var(--yellow) !important;
  background: var(--black) !important;
  border: solid 1px var(--yellow) !important;
}

.high-contrast .language-menu-dropdown-submenu span,
.high-contrast .home-header-bg,
.high-contrast .home-header-title::after {
  filter: grayscale(100%) contrast(120%) !important;
}

.high-contrast .language-menu__submenu {
  background: var(--black) !important;
  border: solid 1px var(--white) !important;
}

/* Perfil */
.high-contrast .profile-menu__submenu {
  background: var(--black) !important;
  border: solid 1px var(--white) !important;
}

/* Home Page */

/* Cards */
.high-contrast .home-service-info-icon {
  color: var(--yellow) !important;
}

.high-contrast .home-service-content {
  border: solid 3px var(--yellow) !important;
}

.high-contrast .home-service-content::before,
.high-contrast .home-service-content::after {
  background: none !important;
}

.high-contrast .home-about-container {
  border: 1px solid var(--white);
}

/* Modal */
.high-contrast .info-modal-button-info {
  border: solid 3px var(--yellow) !important;
}

.high-contrast .info-modal-content {
  border: solid 1px var(--white) !important;
}

.high-contrast .info-modal-description a span {
  color: var(--yellow) !important;
}

/* Consulta de Processos */

.high-contrast .consulta-processo__button--inline button {
  border: solid 1px var(--white) !important;
}

.high-contrast .sds-input-wrapper input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

/* Conferência de Documentos */
.high-contrast .sds-data-table {
  background: var(--black) !important;
}

.high-contrast .orgao-search__table-footer button {
  border: solid 1px var(--white) !important;
}

.high-contrast .sds-modal {
  border: solid 1px var(--white) !important;
}

/* Inputs */
.high-contrast .sds-label {
  background: var(--black) !important;
}

.high-contrast .query-input-container button[class*="sds-input__icon--search"] {
  border-radius: 0.313rem;
}

.high-contrast .processo-form__form--hint {
  border: solid 1px var(--white) !important;
  padding: 0.625rem !important;
}

/* Modal de pesquisa de processo */
.high-contrast .sds-data-table th {
  border: solid 1px var(--white) !important;
}

.high-contrast .sds-data-table__container {
  border: solid 1px var(--white) !important;
}

.high-contrast .sds-data-table tbody tr {
  border: solid 2px var(--white) !important;
}

.high-contrast .sds-data-table td {
  border: solid 1px var(--white) !important;
}


/* Solicitação de Serviços */
.high-contrast .search-button,
.high-contrast .search-services-button {
  border: solid 1px var(--yellow) !important;
}

.high-contrast .search-button i,
.high-contrast .search-services-button i {
  color: var(--yellow) !important;
}

.high-contrast .search-data-result,
.high-contrast .card-service-content,
.high-contrast .card-docs-content {
  border: solid 1px var(--white) !important;
}

.high-contrast .card-service-link-service,
.high-contrast .card-docs-content-info a,
.high-contrast .services-link-solicitar {
  background: var(--black) !important;
  color: var(--yellow) !important;
  border: solid 1px var(--white) !important;
}

.high-contrast .sds-indicator {
  background: var(--white) !important;
}

.high-contrast .list-services-categorys-cards {
  border: solid 1px var(--white) !important;
}

.high-contrast .card-category-content {
  border: solid 1px var(--yellow) !important;
}

.high-contrast .card-category-container {
  background: transparent !important;
}

.high-contrast .card-category-content i {
  color: var(--yellow) !important;
}

.high-contrast .card-category-content-description p {
  color: var(--yellow) !important;
}

.high-contrast .search-data-result a span {
  color: var(--yellow) !important;
}

.high-contrast .list-services__table--container {
  border: solid 1px var(--white) !important;
  background: var(--black) !important;
}

.high-contrast .list-services__table--container th {
  border: solid 1px var(--white) !important;
  background: var(--black) !important;
}

.high-contrast .list-services__table--container td {
  border: solid 1px var(--white) !important;
  color: var(--white) !important;
}

.high-contrast .list-services__table--container td button span svg {
  color: var(--yellow) !important;
}

/* Página Entrar */
.high-contrast .sds-form-group .br-button {
  border: solid 1px var(--white) !important;
}

/* Página de Login e Cadastrar */
.high-contrast .sds-button-format button {
  border: solid 1px var(--white) !important;
}

/* Meus processos */

.high-contrast .content-solicitacoes-EM_ANDAMENTO > div  {
  border: solid 1px var(--yellow) !important;
  color: var(--yellow) !important;
}

.high-contrast .content-solicitacoes-EM_ANDAMENTO > div i {
  color: var(--yellow) !important;
}

.high-contrast .content-solicitacoes-EM_ANDAMENTO > div h3 span {
  color: var(--yellow) !important;
}

.high-contrast .content-solicitacoes-EM_ANDAMENTO > div span {
  color: var(--yellow) !important;
}

.high-contrast .content-solicitacoes-CONCLUIDO > div  {
  border: solid 1px var(--yellow) !important;
  color: var(--yellow) !important;
}

.high-contrast .content-solicitacoes-CONCLUIDO > div i {
  color: var(--yellow) !important;
}

.high-contrast .content-solicitacoes-CONCLUIDO > div h3 span {
  color: var(--yellow) !important;
}

.high-contrast .content-solicitacoes-CONCLUIDO > div span {
  color: var(--yellow) !important;
}

.high-contrast .sds-load__content svg circle {
  stroke: var(--white) !important;
}

/* Minhas Pedências */

.high-contrast .table-custom--container {
  border: solid 1px var(--white) !important;
}

.high-contrast .table-custom--container th,
.high-contrast .table-custom--container td {
  color: var(--white) !important;
}

.high-contrast .table-custom--container td a span {
  color: var(--yellow) !important;
}

/* Processos Liberados */
.high-contrast .released-processes-card-container {
  border: solid 1px var(--white) !important;
}

/* Textos */
.high-contrast h1,
.high-contrast h2,
.high-contrast h3,
.high-contrast h4,
.high-contrast h5,
.high-contrast h6,
.high-contrast p,
.high-contrast label,
.high-contrast strong,
.high-contrast em,
.high-contrast cite,
.high-contrast q,
.high-contrast i,
.high-contrast b,
.high-contrast u,
.high-contrast span {
  color: var(--white) !important;
}

/* Links */
.high-contrast a {
  color: var(--yellow) !important;
}

.high-contrast button,
.high-contrast input[type="button"],
.high-contrast input[type="reset"],
.high-contrast input[type="submit"] {
  background: var(--black) !important;
  color: var(--yellow) !important;
}

/* Imagens */
.high-contrast img {
  filter: grayscale(100%) contrast(120%);
}

/* Campo de formulários */
.high-contrast input[type="text"],
.high-contrast input[type="password"],
.high-contrast input[type="url"],
.high-contrast input[type="search"],
.high-contrast input[type="email"],
.high-contrast input[type="tel"],
.high-contrast input[type="date"],
.high-contrast input[type="month"],
.high-contrast input[type="week"],
.high-contrast input[type="datetime"],
.high-contrast input[type="datetime-local"],
.high-contrast textarea,
.high-contrast input[type="number"] {
  background: var(--black) !important;
  border: 2px solid var(--white) !important;
  color: var(--white) !important;
}

/* Containers */
.high-contrast .sds-container-default,
.high-contrast .sds-grid-container {
  border: solid 1px var(--white) !important;
}

@media only screen and (max-width: 1400px) {
  .high-contrast__title {
    display: none;
  }
}

/* stylelint-disable selector-class-pattern */

.language-menu__container {
  display: flex;
  cursor: pointer;
  right: 100px;
  font-size: 16px;
  z-index: 1;
}

.language-menu__button {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  justify-content: center;
  color: var(--white);
  background: none;
  border: none;
  cursor: pointer;
}

.language-menu__icon {
  font-size: 18px;
}


/* Submenu */
.language-menu__submenu {
  /* width: 100%; */
  background-color: var(--white);
  display: none;
  position: absolute;
  padding: 10px;
  list-style-type: none;
  margin-top: 0.625rem;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
}

.language-menu__languages {
  color: var(--black);
  border: none;
  background: none;
  cursor: pointer;
}

.language-menu__dropdown--active {
  display: block;
}

@media only screen and (max-width: 1400px) {
  .language-menu__title {
    display: none;
  }
}
.icon-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  fill: var(--white);
  transform: rotate(180deg);
  transition: all 150ms;
}

.icon-menu-active {
  transform: rotate(0deg);
}
/* stylelint-disable selector-class-pattern */

.app-tool-bar-skip-to-content {
  background-color: var(--white);
  color: var(--client-main-color);
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  position: absolute;
  left: 0.5rem;
  transform: translateY(-250%);
  transition: transform 325ms ease-in;
  box-shadow: rgb(149 157 165 / 20%) 0 8px 24px;
  text-decoration: none;
  z-index: 1;
}

/* .app-tool-bar-skip-to-content:focus {
  transform: translateY(0);
} */

.app-tool-bar-utils-container {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  align-content: center;
  grid-gap: 20px;
  gap: 20px;
  position: absolute;
  right: 1%;
  height: 4rem;
}

.app-tool-bar-utils-content {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  grid-gap: 40px;
  gap: 40px;
  margin-right: 40px;
}

.app-tool-bar-divider-container {
  height: 0;
  padding: 0;
  margin: 0;
  position: relative;
  display: contents;
}

@media only screen and (max-width: 1280px) {
  .app-tool-bar-utils-container {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .app-menu__logo {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}

/* stylelint-disable selector-class-pattern */
.processo__header-content--padding {
  padding: 20px 0;
}

.processo__tab-content--padding {
  padding: 20px;
}

.processo__tab-content--item {
  display: flex;
}

.processo__tab-content--item-title {
  font-weight: bold;
  text-align: right;
  min-width: 200px;
  max-width: 200px;
}

.processo__tab-content--item div:nth-child(2) {
  padding: 0 5px;
}

.processo__tab-content--subtitle {
  padding: 15px 0;
  font-size: 28px;
}

.processo__tab-content--subtitle div span:nth-child(1) {
  padding-bottom: 5px;
}

.processo__tab-iframe {
  min-height: 700px;
}

@media only screen and (max-width: 36em) {
  .processo__tab-content--item {
    display: inline;
  }

  .processo__tab-content--item-title {
    text-align: initial;
    min-width: initial;
    max-width: initial;
  }

  .processo__tab-content--item div:nth-child(2) {
    padding: 5px 0;
  }
}

.modal-perfils {
    width: 30%;
    background: #fff;
    padding: 1rem;
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0.5rem;
}

.modal-perfils .title {
    color: #135c96;
    margin: 1rem 0;
}

.modal-perfils .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row-gap: 3rem;
    row-gap: 3rem;
    padding: 2rem;
}

.modal-perfils .select-perfil {
    width: 100%;
}

.modal-perfils .info {
    background-color: #edecec;
    padding: 0.5rem;
    text-align: center;
}

.modal-perfils button {
    width: 80%;
}

