:root {
  --primary-color: #00838F;
  --primary-medium: #0097A7;
  --client-main-color: var(--primary-color);
  --client-main-color-rgb-70: var(--primary-medium);
  --client-main-color-rgb-40: var(--primary-light);
  --client-main-color-rgb-10: var(--primary-xl);
  --client-secondary-color: var(--secondary-color);
  --client-secondary-color-rgb-70: var(--secondary-medium);
  --client-secondary-color-rgb-40: var(--secondary-light);
  --client-secondary-color-rgb-10: var(--secondary-xl);
  --client-neutral-color: var(--neutral-color);
  --client-neutral-color-rgb-70: var(--neutral-color-medium);
  --client-neutral-color-rgb-40: var(--neutral-color-light);
  --client-neutral-color-rgb-10: var(--neutral-color-xl);
  --client-background: var(--background);
  --client-background-light: var(--background-light);
  --black: #000;
  --white: #fff;
  --yellow: #ff0;
  --red: #f00;
  --grey: #747474;
  --grey-light: #d3d3d3;
  --grey-dark: #413F42;
}
