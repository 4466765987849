/* stylelint-disable color-function-notation */
/* stylelint-disable selector-not-notation */
.br-button {
  --button-background: transparent;
  --button-border: transparent;
  --button-color: var(--interactive);
  --button-radius: 100em;
  --button-font-size: var(--font-size-scale-up-01);
  --button-font-weight: var(--font-weight-semi-bold);
  --button-xsmall: 24px;
  --button-small: 32px;
  --button-medium: 40px;
  --button-large: 48px;
  --button-size: var(--button-medium);
  --button-padding: 0 var(--spacing-scale-3x);
  --button-img: 20px;
  --interactive-rgb: var(--rgb-primary-default);

  align-items: center;
  background-color: var(--button-background);
  border: 1px solid var(--button-border);
  border-radius: var(--button-radius);
  color: var(--button-color);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--button-font-size);
  font-weight: var(--button-font-weight);
  height: var(--button-size);
  justify-content: center;
  padding: var(--button-padding);
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.br-button.block {
  width: 100%;
}

@media (min-width: 575px) {
  .br-button.block-sm {
    width: 100%;
  }

  .br-button.auto-sm {
    width: auto;
  }
}

@media (min-width: 992px) {
  .br-button.block-md {
    width: 100%;
  }

  .br-button.auto-md {
    width: auto;
  }
}

@media (min-width: 1280px) {
  .br-button.block-lg {
    width: 100%;
  }

  .br-button.auto-lg {
    width: auto;
  }
}

@media (min-width: 1600px) {
  .br-button.block-xl {
    width: 100%;
  }

  .br-button.auto-xl {
    width: auto;
  }
}

.button-login-certificado-digital {
  --button-border: transparent;
  --button-radius: 100em;
  --button-font-size: var(--font-size-scale-up-01);
  --button-font-weight: var(--font-weight-semi-bold);
  --button-xsmall: 24px;
  --button-small: 32px;
  --button-medium: 40px;
  --button-large: 48px;
  --button-size: var(--button-medium);
  --button-padding: 0 var(--spacing-scale-3x);
  --button-img: 20px;

  align-items: center;
  background-color: var(--button-background);
  border: 1px solid var(--button-border);
  border-radius: var(--button-radius);
  color: var(--button-color);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--button-font-size);
  font-weight: var(--button-font-weight);
  height: var(--button-size);
  justify-content: center;
  padding: var(--button-padding);
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;

  --interactive-rgb: var(--rgb-secondary-01);
  --button-background: var(--primary-color);
  --button-color: var(--color-secondary-01);
}

.br-button.primary,
.br-button[primary],
.br-button.is-primary {
  --interactive-rgb: var(--rgb-secondary-01);
  --button-background: var(--color-primary-default);
  --button-color: var(--color-secondary-01);
}

.br-button:not(:disabled):not(:disabled):hover {
  background-image: linear-gradient(
    rgba(var(--interactive-rgb), var(--hover)),
    rgba(var(--interactive-rgb), var(--hover))
  );
}

:root {
  --hover: var(--surface-opacity-xs, 0.16);
  --color-primary-default: #1351b4;
  --color-secondary-01: #fff;
  --font-weight-semi-bold: 600;
  --font-size-scale-up-01: 1.05em;
  --rgb-secondary-01: 255, 255, 255;
}

.govbr-area .descricao {
  background-color: #c7c7c761;
  padding: 5px;
  margin-bottom: 2.75rem;
}
