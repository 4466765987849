/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-class-pattern */
@import "../../../styles/colors.css";

/* Componente */
.high-contrast__content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8125em;
  color: var(--white);
  right: 220px;
  gap: 5px;
  cursor: pointer;
  border: none;
  background: none;
}

.high-contrast__icon {
  font-size: 18px;
}

/* Background */
.high-contrast,
.high-contrast nav,
.high-contrast div,
.high-contrast li,
.high-contrast ol,
.high-contrast header,
.high-contrast footer,
.high-contrast section,
.high-contrast main,
.high-contrast aside,
.high-contrast article {
  background: var(--black) !important;
  color: var(--white) !important;
}

/* Cabeçalho */
.high-contrast .sds-topbar {
  border-bottom: solid 1px var(--white) !important;
}

/* Menu de Navegação */
.high-contrast .sds-sidemenu {
  border-right: solid 1px var(--white) !important;
}

.high-contrast .sds-sidemenu i,
.high-contrast .sds-sidemenu span {
  color: var(--yellow) !important;
}

.high-contrast .sds-sidemenu__item--active a i {
  color: var(--white) !important;
}

/* Menu de idiomas */
.high-contrast .language-menu-dropdown-submenu {
  color: var(--yellow) !important;
  background: var(--black) !important;
  border: solid 1px var(--yellow) !important;
}

.high-contrast .language-menu-dropdown-submenu span,
.high-contrast .home-header-bg,
.high-contrast .home-header-title::after {
  filter: grayscale(100%) contrast(120%) !important;
}

.high-contrast .language-menu__submenu {
  background: var(--black) !important;
  border: solid 1px var(--white) !important;
}

/* Perfil */
.high-contrast .profile-menu__submenu {
  background: var(--black) !important;
  border: solid 1px var(--white) !important;
}

/* Home Page */

/* Cards */
.high-contrast .home-service-info-icon {
  color: var(--yellow) !important;
}

.high-contrast .home-service-content {
  border: solid 3px var(--yellow) !important;
}

.high-contrast .home-service-content::before,
.high-contrast .home-service-content::after {
  background: none !important;
}

.high-contrast .home-about-container {
  border: 1px solid var(--white);
}

/* Modal */
.high-contrast .info-modal-button-info {
  border: solid 3px var(--yellow) !important;
}

.high-contrast .info-modal-content {
  border: solid 1px var(--white) !important;
}

.high-contrast .info-modal-description a span {
  color: var(--yellow) !important;
}

/* Consulta de Processos */

.high-contrast .consulta-processo__button--inline button {
  border: solid 1px var(--white) !important;
}

.high-contrast .sds-input-wrapper input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

/* Conferência de Documentos */
.high-contrast .sds-data-table {
  background: var(--black) !important;
}

.high-contrast .orgao-search__table-footer button {
  border: solid 1px var(--white) !important;
}

.high-contrast .sds-modal {
  border: solid 1px var(--white) !important;
}

/* Inputs */
.high-contrast .sds-label {
  background: var(--black) !important;
}

.high-contrast .query-input-container button[class*="sds-input__icon--search"] {
  border-radius: 0.313rem;
}

.high-contrast .processo-form__form--hint {
  border: solid 1px var(--white) !important;
  padding: 0.625rem !important;
}

/* Modal de pesquisa de processo */
.high-contrast .sds-data-table th {
  border: solid 1px var(--white) !important;
}

.high-contrast .sds-data-table__container {
  border: solid 1px var(--white) !important;
}

.high-contrast .sds-data-table tbody tr {
  border: solid 2px var(--white) !important;
}

.high-contrast .sds-data-table td {
  border: solid 1px var(--white) !important;
}


/* Solicitação de Serviços */
.high-contrast .search-button,
.high-contrast .search-services-button {
  border: solid 1px var(--yellow) !important;
}

.high-contrast .search-button i,
.high-contrast .search-services-button i {
  color: var(--yellow) !important;
}

.high-contrast .search-data-result,
.high-contrast .card-service-content,
.high-contrast .card-docs-content {
  border: solid 1px var(--white) !important;
}

.high-contrast .card-service-link-service,
.high-contrast .card-docs-content-info a,
.high-contrast .services-link-solicitar {
  background: var(--black) !important;
  color: var(--yellow) !important;
  border: solid 1px var(--white) !important;
}

.high-contrast .sds-indicator {
  background: var(--white) !important;
}

.high-contrast .list-services-categorys-cards {
  border: solid 1px var(--white) !important;
}

.high-contrast .card-category-content {
  border: solid 1px var(--yellow) !important;
}

.high-contrast .card-category-container {
  background: transparent !important;
}

.high-contrast .card-category-content i {
  color: var(--yellow) !important;
}

.high-contrast .card-category-content-description p {
  color: var(--yellow) !important;
}

.high-contrast .search-data-result a span {
  color: var(--yellow) !important;
}

.high-contrast .list-services__table--container {
  border: solid 1px var(--white) !important;
  background: var(--black) !important;
}

.high-contrast .list-services__table--container th {
  border: solid 1px var(--white) !important;
  background: var(--black) !important;
}

.high-contrast .list-services__table--container td {
  border: solid 1px var(--white) !important;
  color: var(--white) !important;
}

.high-contrast .list-services__table--container td button span svg {
  color: var(--yellow) !important;
}

/* Página Entrar */
.high-contrast .sds-form-group .br-button {
  border: solid 1px var(--white) !important;
}

/* Página de Login e Cadastrar */
.high-contrast .sds-button-format button {
  border: solid 1px var(--white) !important;
}

/* Meus processos */

.high-contrast .content-solicitacoes-EM_ANDAMENTO > div  {
  border: solid 1px var(--yellow) !important;
  color: var(--yellow) !important;
}

.high-contrast .content-solicitacoes-EM_ANDAMENTO > div i {
  color: var(--yellow) !important;
}

.high-contrast .content-solicitacoes-EM_ANDAMENTO > div h3 span {
  color: var(--yellow) !important;
}

.high-contrast .content-solicitacoes-EM_ANDAMENTO > div span {
  color: var(--yellow) !important;
}

.high-contrast .content-solicitacoes-CONCLUIDO > div  {
  border: solid 1px var(--yellow) !important;
  color: var(--yellow) !important;
}

.high-contrast .content-solicitacoes-CONCLUIDO > div i {
  color: var(--yellow) !important;
}

.high-contrast .content-solicitacoes-CONCLUIDO > div h3 span {
  color: var(--yellow) !important;
}

.high-contrast .content-solicitacoes-CONCLUIDO > div span {
  color: var(--yellow) !important;
}

.high-contrast .sds-load__content svg circle {
  stroke: var(--white) !important;
}

/* Minhas Pedências */

.high-contrast .table-custom--container {
  border: solid 1px var(--white) !important;
}

.high-contrast .table-custom--container th,
.high-contrast .table-custom--container td {
  color: var(--white) !important;
}

.high-contrast .table-custom--container td a span {
  color: var(--yellow) !important;
}

/* Processos Liberados */
.high-contrast .released-processes-card-container {
  border: solid 1px var(--white) !important;
}

/* Textos */
.high-contrast h1,
.high-contrast h2,
.high-contrast h3,
.high-contrast h4,
.high-contrast h5,
.high-contrast h6,
.high-contrast p,
.high-contrast label,
.high-contrast strong,
.high-contrast em,
.high-contrast cite,
.high-contrast q,
.high-contrast i,
.high-contrast b,
.high-contrast u,
.high-contrast span {
  color: var(--white) !important;
}

/* Links */
.high-contrast a {
  color: var(--yellow) !important;
}

.high-contrast button,
.high-contrast input[type="button"],
.high-contrast input[type="reset"],
.high-contrast input[type="submit"] {
  background: var(--black) !important;
  color: var(--yellow) !important;
}

/* Imagens */
.high-contrast img {
  filter: grayscale(100%) contrast(120%);
}

/* Campo de formulários */
.high-contrast input[type="text"],
.high-contrast input[type="password"],
.high-contrast input[type="url"],
.high-contrast input[type="search"],
.high-contrast input[type="email"],
.high-contrast input[type="tel"],
.high-contrast input[type="date"],
.high-contrast input[type="month"],
.high-contrast input[type="week"],
.high-contrast input[type="datetime"],
.high-contrast input[type="datetime-local"],
.high-contrast textarea,
.high-contrast input[type="number"] {
  background: var(--black) !important;
  border: 2px solid var(--white) !important;
  color: var(--white) !important;
}

/* Containers */
.high-contrast .sds-container-default,
.high-contrast .sds-grid-container {
  border: solid 1px var(--white) !important;
}

@media only screen and (max-width: 1400px) {
  .high-contrast__title {
    display: none;
  }
}
