.modal-perfils {
    width: 30%;
    background: #fff;
    padding: 1rem;
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0.5rem;
}

.modal-perfils .title {
    color: #135c96;
    margin: 1rem 0;
}

.modal-perfils .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 3rem;
    padding: 2rem;
}

.modal-perfils .select-perfil {
    width: 100%;
}

.modal-perfils .info {
    background-color: #edecec;
    padding: 0.5rem;
    text-align: center;
}

.modal-perfils button {
    width: 80%;
}
